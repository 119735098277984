
/* ---------------------------------------------------- */
/* Animations Delay */
/* ---------------------------------------------------- */

.animation-delay-1 {
    -webkit-animation-delay: .1s !important;
    -moz-animation-delay: .1s !important;
    -ms-animation-delay: .1s !important;
    -o-animation-delay: .1s !important;
    animation-delay: .1s !important;
}

.animation-delay-2 {
    -webkit-animation-delay: .2s !important;
    -moz-animation-delay: .2s !important;
    -ms-animation-delay: .2s !important;
    -o-animation-delay: .2s !important;
    animation-delay: .2s !important;
}

.animation-delay-3 {
    -webkit-animation-delay: .3s !important;
    -moz-animation-delay: .3s !important;
    -ms-animation-delay: .3s !important;
    -o-animation-delay: .3s !important;
    animation-delay: .3s !important;
}

.animation-delay-4 {
    -webkit-animation-delay: .4s !important;
    -moz-animation-delay: .4s !important;
    -ms-animation-delay: .4s !important;
    -o-animation-delay: .4s !important;
    animation-delay: .4s !important;
}

.animation-delay-5 {
    -webkit-animation-delay: .5s !important;
    -moz-animation-delay: .5s !important;
    -ms-animation-delay: .5s !important;
    -o-animation-delay: .5s !important;
    animation-delay: .5s !important;
}

.animation-delay-6 {
    -webkit-animation-delay: .6s !important;
    -moz-animation-delay: .6s !important;
    -ms-animation-delay: .6s !important;
    -o-animation-delay: .6s !important;
    animation-delay: .6s !important;
}

.animation-delay-7 {
    -webkit-animation-delay: .7s !important;
    -moz-animation-delay: .7s !important;
    -ms-animation-delay: .7s !important;
    -o-animation-delay: .7s !important;
    animation-delay: .7s !important;
}

.animation-delay-8 {
    -webkit-animation-delay: .8s !important;
    -moz-animation-delay: .8s !important;
    -ms-animation-delay: .8s !important;
    -o-animation-delay: .8s !important;
    animation-delay: .8s !important;
}

.animation-delay-9 {
    -webkit-animation-delay: .9s !important;
    -moz-animation-delay: .9s !important;
    -ms-animation-delay: .9s !important;
    -o-animation-delay: .9s !important;
    animation-delay: .9s !important;
}

.animation-delay-10 {
    -webkit-animation-delay: 1s !important;
    -moz-animation-delay: 1s !important;
    -ms-animation-delay: 1s !important;
    -o-animation-delay: 1s !important;
    animation-delay: 1s !important;
}

.animation-delay-11 {
    -webkit-animation-delay: 1.1s !important;
    -moz-animation-delay: 1.1s !important;
    -ms-animation-delay: 1.1s !important;
    -o-animation-delay: 1.1s !important;
    animation-delay: 1.1s !important;
}

.animation-delay-12 {
    -webkit-animation-delay: 1.2s !important;
    -moz-animation-delay: 1.2s !important;
    -ms-animation-delay: 1.2s !important;
    -o-animation-delay: 1.2s !important;
    animation-delay: 1.2s !important;
}

.animation-delay-13 {
    -webkit-animation-delay: 1.3s !important;
    -moz-animation-delay: 1.3s !important;
    -ms-animation-delay: 1.3s !important;
    -o-animation-delay: 1.3s !important;
    animation-delay: 1.3s !important;
}

.animation-delay-14 {
    -webkit-animation-delay: 1.4s !important;
    -moz-animation-delay: 1.4s !important;
    -ms-animation-delay: 1.4s !important;
    -o-animation-delay: 1.4s !important;
    animation-delay: 1.4s !important;
}

.animation-delay-15 {
    -webkit-animation-delay: 1.5s !important;
    -moz-animation-delay: 1.5s !important;
    -ms-animation-delay: 1.5s !important;
    -o-animation-delay: 1.5s !important;
    animation-delay: 1.5s !important;
}

.animation-delay-16 {
    -webkit-animation-delay: 1.6s !important;
    -moz-animation-delay: 1.6s !important;
    -ms-animation-delay: 1.6s !important;
    -o-animation-delay: 1.6s !important;
    animation-delay: 1.6s !important;
}

.animation-delay-17 {
    -webkit-animation-delay: 1.7s !important;
    -moz-animation-delay: 1.7s !important;
    -ms-animation-delay: 1.7s !important;
    -o-animation-delay: 1.7s !important;
    animation-delay: 1.7s !important;
}

.animation-delay-18 {
    -webkit-animation-delay: 1.8s !important;
    -moz-animation-delay: 1.8s !important;
    -ms-animation-delay: 1.8s !important;
    -o-animation-delay: 1.8s !important;
    animation-delay: 1.8s !important;
}

.animation-delay-19 {
    -webkit-animation-delay: 1.9s !important;
    -moz-animation-delay: 1.9s !important;
    -ms-animation-delay: 1.9s !important;
    -o-animation-delay: 1.9s !important;
    animation-delay: 1.9s !important;
}

.animation-delay-20 {
    -webkit-animation-delay: 2s !important;
    -moz-animation-delay: 2s !important;
    -ms-animation-delay: 2s !important;
    -o-animation-delay: 2s !important;
    animation-delay: 2s !important;
}

.animation-delay-21 {
    -webkit-animation-delay: 2.1s !important;
    -moz-animation-delay: 2.1s !important;
    -ms-animation-delay: 2.1s !important;
    -o-animation-delay: 2.1s !important;
    animation-delay: 2.1s !important;
}

.animation-delay-22 {
    -webkit-animation-delay: 2.2s !important;
    -moz-animation-delay: 2.2s !important;
    -ms-animation-delay: 2.2s !important;
    -o-animation-delay: 2.2s !important;
    animation-delay: 2.2s !important;
}

.animation-delay-23 {
    -webkit-animation-delay: 2.3s !important;
    -moz-animation-delay: 2.3s !important;
    -ms-animation-delay: 2.3s !important;
    -o-animation-delay: 2.3s !important;
    animation-delay: 2.3s !important;
}

.animation-delay-24 {
    -webkit-animation-delay: 2.4s !important;
    -moz-animation-delay: 2.4s !important;
    -ms-animation-delay: 2.4s !important;
    -o-animation-delay: 2.4s !important;
    animation-delay: 2.4s !important;
}

.animation-delay-25 {
    -webkit-animation-delay: 2.5s !important;
    -moz-animation-delay: 2.5s !important;
    -ms-animation-delay: 2.5s !important;
    -o-animation-delay: 2.5s !important;
    animation-delay: 2.5s !important;
}

.animation-delay-26 {
    -webkit-animation-delay: 2.6s !important;
    -moz-animation-delay: 2.6s !important;
    -ms-animation-delay: 2.6s !important;
    -o-animation-delay: 2.6s !important;
    animation-delay: 2.6s !important;
}

.animation-delay-27 {
    -webkit-animation-delay: 2.6s !important;
    -moz-animation-delay: 2.6s !important;
    -ms-animation-delay: 2.6s !important;
    -o-animation-delay: 2.6s !important;
    animation-delay: 2.6s !important;
}

.animation-delay-28 {
    -webkit-animation-delay: 2.8s !important;
    -moz-animation-delay: 2.8s !important;
    -ms-animation-delay: 2.8s !important;
    -o-animation-delay: 2.8s !important;
    animation-delay: 2.8s !important;
}

.animation-delay-29 {
    -webkit-animation-delay: 2.9s !important;
    -moz-animation-delay: 2.9s !important;
    -ms-animation-delay: 2.9s !important;
    -o-animation-delay: 2.9s !important;
    animation-delay: 2.9s !important;
}

.animation-delay-30 {
    -webkit-animation-delay: 3s !important;
    -moz-animation-delay: 3s !important;
    -ms-animation-delay: 3s !important;
    -o-animation-delay: 3s !important;
    animation-delay: 3s !important;
}

.animation-delay-40 {
    -webkit-animation-delay: 4.0s !important;
    -moz-animation-delay: 4.0s !important;
    -ms-animation-delay: 4.0s !important;
    -o-animation-delay: 4.0s !important;
    animation-delay: 4.0s !important;
}

.animation-delay-45 {
    -webkit-animation-delay: 4.5s !important;
    -moz-animation-delay: 4.5s !important;
    -ms-animation-delay: 4.5s !important;
    -o-animation-delay: 4.5s !important;
    animation-delay: 4.5s !important;
}
